<template>
  <v-container>
    <v-row>
        <v-col cols="12">
            <v-data-table
          :headers="headers"
          :items="requests"
          :footer-props="{
            'items-per-page-options': [5, 10, 15, 30, 50, , -1],
          }"
          :options="options"
          class="elevation-6 py-7"
          :search="search"
        >
          <template v-slot:top>
            <v-toolbar flat class="pb-5">
              <v-toolbar-title><v-icon class="mr-5" color="primary">mdi-briefcase</v-icon>Request Pa bijstand</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{}">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>View case</span>
            </v-tooltip>
          </template>
        </v-data-table>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
    data(){
        return{
            options: {
                itemsPerPage: 10,
            },
            search:'',
            requests:[],
            headers: [
                {
                text: "ID",
                value: "id",
                align: "center",
                class: "primary white--text px-0 mx-0",
                },
                {
                text: "Costelacion di famia",
                value: "costelacion",
                align: "center",
                class: "primary white--text px-0 mx-0",
                },
                {
                text: "Aplicante",
                value: "aplicante",
                align: "center",
                class: "primary white--text px-0 mx-0",
                },
                {
                text: "Pareha",
                value: "pareha",
                align: "center",
                class: "primary white--text px-0 mx-0",
                },
                {
                text: "Yiu",
                value: "cant_Yiu",
                align: "center",
                class: "primary white--text px-0 mx-0",
                },
                {
                text: "Otra entrada",
                value: "otra_entrada",
                align: "center",
                class: "primary white--text px-0 mx-0",
                },
                {
                text: "Alimentacion",
                value: "alimentacion",
                align: "center",
                class: "primary white--text px-0 mx-0",
                },
                {
                text: "Vivienda",
                value: "vivienda",
                align: "center",
                class: "primary white--text px-0 mx-0",
                },
                {
                text: "Tools",
                value: "actions",
                align: "center",
                class: "primary white--text px-0 mx-0",
                },
            ],
        }
    }
}
</script>

<style>

</style>